@font-face {
    font-family: 'Lagu Sans';
    src: url('fonts/LaguSans-ExtraBold.woff2') format('woff2'), url('fonts/LaguSans-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lagu Sans';
    src: url('fonts/LaguSans-Black.woff2') format('woff2'), url('fonts/LaguSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lagu Sans';
    src: url('fonts/LaguSans-Bold.woff2') format('woff2'), url('fonts/LaguSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lagu Sans';
    src: url('fonts/LaguSans-Light.woff2') format('woff2'), url('fonts/LaguSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lagu Sans';
    src: url('fonts/LaguSans-Regular.woff2') format('woff2'), url('fonts/LaguSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lagu Sans';
    src: url('fonts/LaguSans-Medium.woff2') format('woff2'), url('fonts/LaguSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
