/*
* ----------------------------------------------------------------------
* 3RD PARTIES STYLING OVERRIDES
* ----------------------------------------------------------------------
*/

// REACT DATEPICKER ---------------------------------
.react-datepicker__header {
    background: darken($card-bg, 4%) !important;
    border-color: darken($card-bg, 4%) !important;
    color: $body-color !important;
}

.react-datepicker {
    font-family: $font-family-sans-serif !important;
    border: none !important;
    box-shadow: $box-shadow-lg !important;
    background-color: darken($card-bg, 4%) !important;
    user-select: none !important;
}

.react-datepicker__navigation {
    top: 14px !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: $body-color !important;
}

.react-datepicker__day {
    &:hover {
        background-color: lighten($card-bg, 3%) !important;
    }
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: darken($card-bg, 4%) !important;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-top-color: darken($card-bg, 4%) !important;
}

.react-datepicker__day {
    margin: 0.28rem !important;
}

.react-datepicker__day--selected {
    background: $primary !important;
}

.react-datepicker {
    padding: 6px;
    &::before,
    &::after {
        display: none;
    }
}

.react-datepicker-popper {
    z-index: 999 !important;
}

// REACT TOASTIFY ---------------------------------
.react-toast-notifications__toast.react-toast-notifications__toast--error {
    background: rgb(220, 53, 69, 0.8) !important;
    color: white !important;
}

.react-toast-notifications__toast.react-toast-notifications__toast--success {
    background: rgb(0, 188, 212, 0.88) !important;
    color: white !important;
}

.react-toast-notifications__toast.react-toast-notifications__toast--warning {
    background: rgb(255, 152, 0, 0.8) !important;
    color: white !important;
}

.react-toast-notifications__toast__icon-wrapper {
    background: none !important;
}

.Toastify__toast-container--top-center {
    top: 5em !important;
}

.Toastify__toast--error {
    background: rgba($danger, 0.7) !important;
    color: $body-color !important;

    svg {
        fill: $body-color !important;
    }

    .Toastify__progress-bar {
        background: darken($danger, 30%) !important;
    }
}

.Toastify__toast--success {
    background: rgba($info, 0.7) !important;
    color: $body-color !important;

    svg {
        fill: $body-color !important;
    }

    .Toastify__progress-bar {
        background: darken($info, 20%) !important;
    }
}

.Toastify__close-button {
    opacity: 1 !important;
}

// REACT DATATABLES ---------------------------------
.rdt_Table {
    min-width: 1000px !important;
    background: darken($card-bg, 2%) !important;
    border-radius: 0.5rem !important;
    overflow: hidden !important;
}

.rdt_TableHeadRow {
    border: none !important;
    border-radius: 0.5rem;
    background: darken($card-bg, 2%) !important;
    margin-bottom: 1rem !important;
    color: #f5f5f5 !important;
}

.rdt_TableHeadRow .rdt_TableCol {
    font-size: 1rem !important;
    font-weight: 700;
    color: #f5f5f5 !important;
    background: $body-bg !important;
}

.rdt_TableCell {
    font-size: 0.95rem !important;
    white-space: nowrap !important;
    color: #f5f5f5 !important;
    background: darken($card-bg, 2%) !important;
}

.rdt_TableRow {
    transition: all 0.25s;
    border-radius: 0.5rem;
    background: darken($card-bg, 2%) !important;
    color: #f5f5f5 !important;
    padding: 0.9rem 0 !important;

    &:first-of-type {
        margin-top: 0 !important;
    }
}

.rdt_Table .rdt_TableBody {
    overflow: hidden !important;
}

.rdt_Pagination {
    border: none !important;
    justify-content: center !important;
    background: darken($card-bg, 2%) !important;
    color: #ddd !important;
}

.rdt_Pagination button:not(:disabled) {
    color: rgb(255 255 255 / 80%) !important;
    fill: rgb(255 255 255 / 80%) !important;
}

.rdt_Pagination button:disabled {
    color: rgb(255 255 255 / 30%) !important;
    fill: rgb(255 255 255 / 30%) !important;
}

.rdt_TableRow:not(:last-of-type) {
    border: none !important;
}

.table-sm {
    .rdt_Table {
        min-width: 700px !important;
    }
}

.rdt_TableFooter {
    background: darken($card-bg, 2%) !important;
}

// REACT SELECT ---------------------------------
.select__control {
    padding: 0.19rem 0.375rem !important;
    cursor: pointer !important;
    background-color: transparent !important;
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
        box-shadow: none !important;
    }
}

.select__indicator-separator {
    background-color: $input-border-color !important;
    display: none !important;
}

.border-0 {
    .select__control {
        border: 2px solid $input-border-color !important;
    }
}

.select__input-container,
.select__single-value {
    color: $gray-300 !important;
}

.select__dropdown-indicator {
    svg {
        color: $gray-300;
    }
}

.select__menu {
    background: lighten($card-bg, 10%) !important;
}
.select__control {
    border: 2px solid $input-border-color !important;
}

.select__menu {
    background: $card-bg !important;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1) !important;
}

.select__option.select__option--is-selected {
    background: $primary !important;
}

.select__option {
    border-radius: 0.25rem !important;
    background-color: transparent !important;
    font-size: 0.9rem !important;
}

.select__option:not(.select__option--is-selected) {
    &:hover {
        background: lighten($card-bg, 1%) !important;
        cursor: pointer !important;
    }
}

.select__menu-list {
    background: lighten($card-bg, 6%) !important;
    padding: 5px !important;
    border-radius: 0.25rem !important;
}

.select__placeholder {
    font-size: 0.85rem !important;
    color: $input-placeholder-color !important;
}

// REACT TOOLTIP ---------------------------------
.tooltip-container {
    border: none !important;
    color: darken($text-muted, 6%) !important;
    font-weight: $font-weight-normal !important;
    font-size: 0.85rem !important;
    padding: 0.5rem 1rem !important;
    box-shadow: $box-shadow !important;
    max-width: 200px !important;
    border-radius: $border-radius !important;

    &.tooltip-visible {
        box-shadow: none !important;
        background-color: $gray-800 !important;
        padding: 0.3rem 0.5rem !important;
        border: 1px solid $gray-800 !important;
        color: $gray-100;

        .tooltip-arrow {
            &::before,
            &::after {
                left: 0 !important;
                border-width: 0.354rem 0 0.354rem 0.8em !important;
                border-color: transparent transparent transparent $gray-800 !important;
            }
        }
    }
}

// REACT SWIPER ---------------------------------
.swiper-padding-x .swiper-wrapper {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.swiper-fade .swiper-slide:not(.swiper-slide-active) {
    opacity: 0 !important;
}

.swiper-wrapper-padding .swiper-wrapper {
    padding: 2rem 0;
}

// LOADER SPINNER ---------------------------------
.loader-spinner {
    width: 48px;
    height: 48px;
    border: 4px solid;
    background: rgba(255, 255, 255, 0.2);
    border-color: transparent #fff #fff transparent;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s ease-in-out infinite;
}
.loader-spinner::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 12px solid;
    border-color: transparent $primary $primary transparent;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// AOS ANIMATION ---------------------------------
[data-aos^='fade'][data-aos^='fade'] {
    @include media-breakpoint-down(lg) {
        opacity: 1 !important;
        transform: none !important;
    }
}

.react-pagination {
    &.justify-content-start {
        ul {
            padding-left: 0 !important;
            justify-content: flex-start !important ;
        }
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;

        li {
            margin: 0 0.25rem;

            &:first-of-type,
            &:last-of-type {
                a {
                    font-weight: 300;
                    font-size: 0.95rem;
                }

                &.disabled {
                    opacity: 0.5;

                    a {
                        cursor: default;
                        &:hover {
                            background: rgba($secondary, 0.1);
                            color: lighten($secondary, 1%);
                        }
                    }
                }
            }

            &.selected {
                a {
                    background: $primary;
                    color: white;
                    cursor: default;
                    &:hover {
                        background: $primary;
                        color: white;
                    }
                }
            }
            a {
                padding: 0.25rem 0.75rem;
                border-radius: 0.3rem;
                display: block;
                background: rgba($secondary, 0.1);
                color: lighten($secondary, 1%);
                text-decoration: none;
                font-size: 0.95rem;
                transition: all 0.3s;

                &:hover {
                    background: rgba($secondary, 0.2);
                    color: lighten($secondary, 40%);
                }
            }
        }
    }
}

.tippy-popper {
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
}

.tippy-tooltip,
.tippy-tooltip.tippy-tooltip--regular dark-theme.enter.tippy-notransition {
    padding: 1rem !important;
    background-color: lighten($card-bg, 4%) !important;
    font-size: 0.8rem !important;
}

// QUILL EDITOR ----------------------------------------------
.ql-editor {
    min-height: 200px;
}

.ql-container.ql-snow {
    border-color: $input-border-color !important;
    border-width: 2px !important;
    border-bottom-left-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
}

.ql-toolbar {
    border-color: $input-border-color !important;
    border-width: 2px !important;
    border-top-left-radius: $input-border-radius !important;
    border-top-right-radius: $input-border-radius !important;
}

.ql-toolbar .ql-stroke {
    fill: none !important;
    stroke: $gray-600 !important;
}

.ql-toolbar .ql-fill {
    fill: $gray-600 !important;
    stroke: none !important;
}

.ql-toolbar .ql-picker {
    color: $gray-600 !important;
}

div:has(.react-viewer) {
    position: relative !important;
    z-index: 99999 !important;
}

.react-viewer-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.range-slider {
    height: 4px !important;
    background: lighten($body-bg, 10%) !important;
}
.range-slider .range-slider__range {
    background: $primary !important;
}
.range-slider .range-slider__thumb {
    width: 16px !important;
    height: 16px !important;
    background: $primary !important;
}

.wc-btn {
    button {
        height: 20px !important;
    }
}

.w3m-overlay.w3m-open {
    z-index: 9999 !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
}
