.fullscreen-loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999 !important;

    &-inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($body-bg, 0.97);
        position: relative;
        z-index: 9999;
    }
}

.cloud {
    margin: 42px 30px;
    width: 4px;
    height: 10px;
    opacity: 0.5;
    position: relative;
    box-shadow: 6px 0px 0px 0px rgba(255, 255, 255, 1), 12px 0px 0px 0px rgba(255, 255, 255, 1),
        18px 0px 0px 0px rgba(255, 255, 255, 1), 24px 0px 0px 0px rgba(255, 255, 255, 1),
        30px 0px 0px 0px rgba(255, 255, 255, 1), 36px 0px 0px 0px rgba(255, 255, 255, 1);

    -webkit-animation: rain 1s linear infinite alternate;
    -moz-animation: rain 1s linear infinite alternate;
    animation: rain 1s linear infinite alternate;
}
.cloud:after {
    width: 40px;
    height: 10px;
    position: absolute;
    content: '';
    background-color: rgba(255, 255, 255, 1);
    top: 0px;
    opacity: 1;
    -webkit-animation: line_flow 2s linear infinite reverse;
    -moz-animation: line_flow 2s linear infinite reverse;
    animation: line_flow 2s linear infinite reverse;
}

@keyframes rain {
    0% {
        box-shadow: 6px 0px 0px 0px rgba(255, 255, 255, 1), 12px 0px 0px 0px rgba(255, 255, 255, 0.9),
            18px 0px 0px 0px rgba(255, 255, 255, 0.7), 24px 0px 0px 0px rgba(255, 255, 255, 0.6),
            30px 0px 0px 0px rgba(255, 255, 255, 0.3), 36px 0px 0px 0px rgba(255, 255, 255, 0.2);
    }
    100% {
        box-shadow: 6px 0px 0px 0px rgba(255, 255, 255, 0.2), 12px 0px 0px 0px rgba(255, 255, 255, 0.3),
            18px 0px 0px 0px rgba(255, 255, 255, 0.6), 24px 0px 0px 0px rgba(255, 255, 255, 0.7),
            30px 0px 0px 0px rgba(255, 255, 255, 0.9), 36px 0px 0px 0px rgba(255, 255, 255, 1);
        opacity: 1;
    }
}

@-webkit-keyframes line_flow {
    0% {
        width: 0px;
    }
    100% {
        width: 40px;
    }
}
@-moz-keyframes line_flow {
    0% {
        width: 0px;
    }
    100% {
        width: 40px;
    }
}
@keyframes line_flow {
    0% {
        width: 0px;
    }
    100% {
        width: 40px;
    }
}

/* Me */

.intro-loader {
    &::before {
        content: '';
        display: block;
        width: 250px;
        height: 250px;
        background-size: 100% 100%;
        position: absolute;
        top: -36px;
        left: -42px;
        opacity: 0.6;
    }

    &::after {
        content: '';
        display: block;
        width: 300px;
        height: 300px;
        background-size: 100% 100%;
        position: absolute;
        top: calc(100vh - 150px);
        right: -150px;
        opacity: 0.91;
    }
}

.loader {
    display: inline-block;
    width: 45px;
    height: 60px;
    position: relative;
    border: 4px solid #309aa1;
    top: 50%;
    animation: loader 2s infinite ease;
}

.loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #309aa1;
    animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

[data-aos] {
    @include media-breakpoint-down(md) {
        opacity: 1 !important;
        transform: none !important;
    }
}
