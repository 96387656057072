.user-avatar-pic-outer {
    width: 3.1rem;
    height: 3.1rem;
    border-radius: 50%;
    flex-shrink: 0;
    position: relative;
    background: lighten($card-bg, 10%);

    &::after {
        content: '';
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url('/user-icon.svg');
        background-size: 100%;
        background-position: center center;
    }

    .user-avatar-pic {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover !important;
        background-position: center center !important;
        z-index: 99;
        // background-color: lighten($card-bg, 10%);
    }
}

.verified-badge {
    background: rgba($info, 0.2);
    color: lighten($info, 20%);
    padding: 0.35rem 0.75rem 0.2rem;
    font-size: 0.8rem;
    font-weight: 300;
    border-radius: 0.375rem;
}

.unverified-badge {
    background: rgba($warning, 0.1);
    color: lighten($warning, 20%);
    padding: 0.35rem 0.75rem 0.2rem;
    font-size: 0.8rem;
    font-weight: 300;
    border-radius: 0.375rem;
}

.order-status {
    padding: 0.35rem 0.75rem 0.3rem;
    font-size: 0.8rem;
    font-weight: 300;
    border-radius: 0.375rem;

    &.pending {
        background: rgba($warning, 0.1);
        color: lighten($warning, 20%);
    }
    &.canceled {
        background: rgba($danger, 0.1);
        color: lighten($danger, 20%);
    }
    &.shipped {
        background: rgba($info, 0.1);
        color: lighten($info, 20%);
    }
    &.delivered {
        background: rgba($success, 0.1);
        color: lighten($success, 20%);
    }
}

.user-banner {
    padding-top: 9rem;

    &-info {
        z-index: 999;
        position: relative;
    }
    &-profile {
        background-color: $body-bg;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 3px solid lighten($body-bg, 10%);
        background-size: cover !important;
        background-position: center center !important;
    }
}
