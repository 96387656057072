.countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    // color: $gray-600;
    flex-wrap: wrap;
    &-item-number {
        width: 100px;
        height: 100px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
        border: 3px solid darken($gray-800, 6%);
        border-radius: 1rem;
        margin-bottom: 0.25rem;
        color: $gray-100;
        background: $gray-800;
    }
    &-item {
        margin: 0.5rem;
        flex-shrink: 0;
        @include media-breakpoint-down(md) {
            width: calc(50% - 1rem);
            margin: 0.5rem;
            &:nth-of-type(odd) {
                text-align: right;
                .countdown-item-number {
                    margin-left: auto;
                }
                span {
                    margin-left: auto;
                    margin-right: 50px;
                    transform: translateX(50%);
                    display: inline-block;
                }
            }
            &:nth-of-type(even) {
                text-align: left;
                span {
                    margin-left: auto;
                    transform: translateX(50%);
                    display: inline-block;
                }
            }
        }
    }
}
