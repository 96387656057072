$body-bg-light: #fff;
$body-color-light: #202020;
$card-bg-light: #f5f5f5;

$theme-colors-light: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'dark': $dark,
);

html[data-theme='light'] {
    body {
        color: $body-color-light;
        background-color: $body-bg-light;
    }

    .text-reset {
        color: $body-color-light;
    }

    .user-avatar-pic-outer {
        background: lighten($card-bg-light, 5%);
    }

    .card {
        // background: $card-bg-light;
        background: $white;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.07) !important;

        &.shadow-0 {
            box-shadow: none !important;
        }
    }

    .logo-img {
        mix-blend-mode: multiply;
        filter: invert(0%);
    }

    @each $color, $value in $theme-colors {
        .bg-opac-#{$color} {
            background: rgba($value, 0.2);
            color: darken($value, 15%);
            border: none !important;
            position: relative;
        }
        .bg-opac-secondary {
            background: #f5f5f5;
            color: #444;
        }
    }

    .page-banner {
        background: $body-bg-light;
        &::before {
            background: linear-gradient(
                to bottom,
                darken($body-bg-light, 10%),
                rgba(darken($body-bg-light, 10%), 0.9) 40%,
                rgba(darken($body-bg-light, 10%), 0.1) 90%,
                rgba(darken($body-bg-light, 10%), 0) 100%
            );
        }

        &.with-bg {
            &::before {
                background: linear-gradient(
                    to bottom,
                    darken($body-bg-light, 10%),
                    rgba(darken($body-bg-light, 10%), 0.9) 40%,
                    white 90%,
                    white 100%
                );
            }
        }
    }

    .navbar {
        .nav-link {
            color: $body-color-light !important;

            &.active {
                color: $primary !important;
            }
        }

        &::before {
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            background: rgba($body-bg-light, 0.96) !important;
        }
    }

    .bg-gray-850 {
        background: $gray-100 !important;
    }

    // .text-white {
    //     color: $body-color-light !important;
    // }

    .text-gray-500 {
        color: $gray-600 !important;
    }

    .main-footer {
        background: darken($body-bg-light, 4%);
    }

    // REACT DATATABLES ---------------------------------
    .rdt_Table {
        min-width: 1000px !important;
        background: #fafafa !important;
        border-radius: 0.5rem !important;
        overflow: hidden !important;
    }

    .rdt_TableHeadRow {
        border: none !important;
        border-radius: 0.5rem;
        background: #fafafa !important;
        margin-bottom: 1rem !important;
        color: #333 !important;
    }

    .rdt_TableHeadRow .rdt_TableCol {
        font-size: 1rem !important;
        font-weight: 700;
        background: #f0f0f0 !important;
        color: #333 !important;
    }

    .rdt_TableCell {
        font-size: 0.95rem !important;
        white-space: nowrap !important;
        color: #333 !important;
        background: #fafafa !important;
    }

    .rdt_TableRow {
        transition: all 0.25s;
        border-radius: 0.5rem;
        background: #fafafa !important;
        color: #333 !important;
        padding: 0.9rem 0 !important;

        &:first-of-type {
            margin-top: 0 !important;
        }
    }

    .rdt_Table .rdt_TableBody {
        overflow: hidden !important;
    }

    .rdt_Pagination {
        border: none !important;
        justify-content: center !important;
        background: #fafafa !important;
        color: #4b4b4b !important;
    }

    .rdt_Pagination button:not(:disabled) {
        color: rgb(255 255 255 / 80%) !important;
        fill: rgb(255 255 255 / 80%) !important;
    }

    .rdt_Pagination button:disabled {
        color: rgb(255 255 255 / 30%) !important;
        fill: rgb(255 255 255 / 30%) !important;
    }

    .rdt_TableRow:not(:last-of-type) {
        border: none !important;
    }

    .table-sm {
        .rdt_Table {
            min-width: 700px !important;
        }
    }

    .rdt_TableFooter {
        background: #fafafa !important;
    }

    .verified-badge {
        background: rgba($info, 0.2);
        color: darken($info, 10%);
    }

    .unverified-badge {
        background: rgba($warning, 0.1);
        color: darken($warning, 10%);
    }

    .form-check-input {
        background-color: #d1d1d1;
    }
    .form-check-input:checked {
        background-color: $primary;
    }
    .bg-gray-900 {
        background: #f5f5f5 !important;
    }
    .text-gray-500 {
        color: #a4a4a4 !important;
    }

    .table-bordered {
        > :not(caption) > * {
            border-width: $table-border-width 0;
            border-color: #ddd !important;

            // stylelint-disable-next-line selector-max-universal
            > * {
                border-width: 0 $table-border-width;
                border-color: #ddd !important;
            }
        }
    }

    .table {
        color: $body-color-light;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 3rem;
        height: 3rem;
        background: lighten($body-bg-light, 4%);
        border: 6px solid $body-bg-light;
        border-radius: 50%;

        &::after {
            font-size: 17px;
            color: rgb(34, 34, 34);
        }
    }

    .quantity {
        button {
            border: none;
            background: none;
            outline: none !important;
            color: $body-color-light !important;
        }
        border: 3px solid #ddd;
    }

    .dropdown-menu {
        background: white;
        box-shadow: 0 10px 18px rgba(0, 0, 0, 0.1) !important;
    }

    .dropdown-item {
        color: $body-color-light;
        &:hover {
            color: lighten($body-color-light, 10%);
            background: #f5f5f5;
        }

        &.disabled {
            color: $gray-500;
        }

        &.active {
            color: white;
            &:hover {
                background: $primary;
            }
        }
    }

    .order-status {
        &.pending {
            color: darken($warning, 10%);
        }
        &.canceled {
            color: darken($danger, 10%);
        }
        &.shipped {
            color: darken($info, 10%);
        }
        &.delivered {
            color: darken($success, 10%);
        }
    }

    .fullscreen-loader {
        &-inner {
            background: rgba($body-bg-light, 0.97);

            &.modal {
                background: rgba($dark, 0.6) !important;
            }
        }
    }

    .cloud {
        box-shadow: 6px 0px 0px 0px rgba(0, 0, 0, 1), 12px 0px 0px 0px rgba(0, 0, 0, 1),
            18px 0px 0px 0px rgba(0, 0, 0, 1), 24px 0px 0px 0px rgba(0, 0, 0, 1), 30px 0px 0px 0px rgba(0, 0, 0, 1),
            36px 0px 0px 0px rgba(0, 0, 0, 1) !important;
    }
    .cloud:after {
        background-color: rgba(0, 0, 0, 1) !important;
    }

    @keyframes rain {
        0% {
            box-shadow: 6px 0px 0px 0px rgba(0, 0, 0, 1), 12px 0px 0px 0px rgba(0, 0, 0, 0.9),
                18px 0px 0px 0px rgba(0, 0, 0, 0.7), 24px 0px 0px 0px rgba(0, 0, 0, 0.6),
                30px 0px 0px 0px rgba(0, 0, 0, 0.3), 36px 0px 0px 0px rgba(0, 0, 0, 0.2) !important;
        }
        100% {
            box-shadow: 6px 0px 0px 0px rgba(0, 0, 0, 0.2), 12px 0px 0px 0px rgba(0, 0, 0, 0.3),
                18px 0px 0px 0px rgba(0, 0, 0, 0.6), 24px 0px 0px 0px rgba(0, 0, 0, 0.7),
                30px 0px 0px 0px rgba(0, 0, 0, 0.9), 36px 0px 0px 0px rgba(0, 0, 0, 1) !important;
            opacity: 1;
        }
    }

    .btn.btn-dark.bg-gray-850 {
        color: lighten($body-color-light, 10%) !important;
        background: #ddd !important;
    }

    .form-control {
        border-color: darken($card-bg-light, 15%);
        color: $body-color-light;
        &::placeholder {
            color: $gray-500;
        }
    }
    .form-select {
        border-color: darken($card-bg-light, 15%);
        color: $body-color-light;
        &::placeholder {
            color: $gray-500;
        }
    }

    .form-floating .form-control,
    .form-floating .form-select {
        &::placeholder {
            color: transparent !important;
        }
    }

    .form-label {
        color: darken($gray-600, 5%);
        & + .shadow-sm {
            box-shadow: none !important;
        }
    }
    // REACT SELECT ---------------------------------

    .select__indicator-separator {
        background-color: $gray-700 !important;
    }

    .border-0 {
        .select__control {
            border: 2px solid darken($card-bg-light, 15%) !important;
        }
    }

    .select__input-container,
    .select__single-value {
        color: $gray-700 !important;
    }

    .select__dropdown-indicator {
        svg {
            color: $gray-700;
        }
    }

    .select__menu {
        background: lighten($card-bg-light, 10%) !important;
    }
    .select__control {
        border: 2px solid darken($card-bg-light, 15%) !important;
    }

    .select__menu {
        background: $white !important;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1) !important;
    }

    .select__option.select__option--is-selected {
        background: $primary !important;
    }

    .select__option:not(.select__option--is-selected) {
        &:hover {
            background: $gray-100 !important;
            color: $body-color-light !important;
        }
    }

    .select__menu-list {
        background: $white !important;
    }

    .select__placeholder {
        font-size: 0.85rem !important;
        color: $gray-500 !important;
    }

    .ql-container.ql-snow {
        border-color: darken($card-bg-light, 15%) !important;
    }

    .ql-toolbar {
        border-color: darken($card-bg-light, 15%) !important;
    }

    .ql-toolbar .ql-stroke {
        stroke: $gray-600 !important;
    }

    .ql-toolbar .ql-fill {
        fill: $gray-600 !important;
        stroke: none !important;
    }

    .ql-toolbar .ql-picker {
        color: $gray-600 !important;
    }

    .form-control {
        &:disabled,
        &[readonly] {
            background-color: darken($card-bg-light, 5%);
            border-color: darken($card-bg-light, 15%);
            color: $gray-500;
        }

        // File input buttons theming
        &::file-selector-button {
            color: $gray-700;
            // @include gradient-bg($form-file-button-bg);
            background-color: darken($card-bg-light, 10%);
        }

        &:hover:not(:disabled):not([readonly])::file-selector-button {
            background-color: darken($card-bg-light, 10%);
        }

        &::-webkit-file-upload-button {
            @include gradient-bg($form-file-button-bg);
            // background: darken($card-bg, 2%);
            background: darken($card-bg-light, 10%);
        }

        &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
            // background-color: darken($card-bg, 3%);
            background-color: darken($card-bg-light, 10%);
        }
    }

    .accordion-button {
        color: $body-color-light !important;
        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333333'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }

        &:not(.collapsed) {
            &::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333333'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            }
        }
    }

    .border-bottom {
        border-color: darken($body-bg-light, 10%) !important;
    }

    .btn-filter {
        color: $body-color-light;
    }
    .range-slider {
        height: 4px !important;
        background: darken($body-bg-light, 10%) !important;
    }

    .hero-slide {
        &::before {
            content: '';
            width: 100%;
            height: 8rem;
            z-index: 10;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, $white, rgba($white, 0.5) 40%, rgba($white, 0) 100%);
        }
    }
    .navbar-toggler .navbar-toggler-icon-el span {
        background-color: #000;
    }
    .navbar-toggler .navbar-toggler-icon-el span::before,
    .navbar-toggler .navbar-toggler-icon-el span::after {
        background-color: #000;
    }
    .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon-el span {
        background-color: transparent;
    }
}
